import React, { useContext, useEffect } from "react"

import { Container, Row, Col, Button } from "react-bootstrap"
import { Link } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import PageWrapper from "../components/PageWrapper"
import Hero from "../sections/common/Hero"
import Support from "../sections/common/Support"
import Contact from "../sections/common/Contact"
import { Section, Title, Box } from "../components/Core"

import GlobalContext from "../contexts/GlobalContext"
import { allLangs } from "../utils/configClient"
import { slugHome, slugCareer } from "../utils/slugs"

import ProductAccordion from "../components/ProductAccordion"
import ProductSlider from "../components/ProductSlider"

import bgImg from "../assets/images/bg5.jpg"

const Career = ({ data }) => {
  const gContext = useContext(GlobalContext)

  useEffect(() => {
    if (gContext.currentLang !== allLangs.default) {
      gContext.setCurrentLang(allLangs.default)
    }
  }, [])

  const pData = data.prismicCareerPage?.data
  const allPrismicJobs = data.allPrismicJob.nodes
  const { btn_home } = data.prismicCommon?.data
  return (
    <>
      <GatsbySeo
        title={pData.meta_title?.text}
        description={pData.meta_description?.text}
      />
      <div>
        <PageWrapper>
          <Hero bgImg={pData?.cover_image.url || bgImg}>
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <Title variant="hero" color="light">
                    {pData?.title ? pData?.title.text : "Career"}
                  </Title>

                  <Box mt={4}>
                    <div className="d-flex my-3 justify-content-center">
                      <div className="text-white font-weight-bold">
                        {">>"}
                        <Link
                          to={slugHome(gContext.currentLang)}
                          className="text-white font-weight-bold"
                        >
                          {btn_home ? btn_home.text : "Home"}
                        </Link>
                      </div>
                    </div>
                  </Box>
                </Col>
              </Row>
            </Container>
          </Hero>

          <Section
            pt={["45px !important", null, null, "60px !important"]}
            className="pb-5"
          >
            <Container fluid>
              <Row className="justify-content-center">
                <Col lg="9" className="px-md-5">
                  <Container>
                    <Row>
                      <Col md={12}>
                        <Box mb="40px">
                          <div className="d-flex my-3 justify-content-left">
                            <div className="font-weight-bold">
                              {">>"}
                              <Link
                                to={slugHome(gContext.currentLang)}
                                className="font-weight-bold breadcrumb-link "
                              >
                                {btn_home ? btn_home.text : "Home"}
                              </Link>
                            </div>
                            <div className="font-weight-bold ml-2">
                              {">>"}
                              <Link
                                to={slugCareer(gContext.currentLang)}
                                className="font-weight-bold breadcrumb-link "
                              >
                                {"Career"}
                              </Link>
                            </div>
                          </div>
                        </Box>
                        <Box className="text-center" mb={5}>
                          <Title mb={3}>
                            {pData?.section_title
                              ? pData?.section_title.text
                              : "Section Title"}
                          </Title>
                          <div
                            className="mb-5"
                            dangerouslySetInnerHTML={{
                              __html: pData?.section_content.html,
                            }}
                          />
                          <h4 className="text-primary pt-4">
                            {pData?.job_listing_title
                              ? pData?.job_listing_title.text
                              : "Job Openings"}
                          </h4>
                        </Box>
                        <Box>
                          {allPrismicJobs.length > 0 &&
                            allPrismicJobs.map(
                              ({ data: { title, content, date } }, i) => (
                                <Box
                                  key={i}
                                  pt={4}
                                  pb={3}
                                  css={`
                                    &:not(:last-child) {
                                      border-bottom: ${({ theme }) =>
                                        `1px solid ${theme.colors.lightShade}`};
                                    }
                                  `}
                                >
                                  <h5 className="mb-3">{title.text}</h5>
                                  {date && (
                                    <p>
                                      <small>
                                        <strong>{date}</strong>
                                      </small>
                                    </p>
                                  )}
                                  <div
                                    className="mb-3"
                                    dangerouslySetInnerHTML={{
                                      __html: content.html,
                                    }}
                                  />
                                  <Box className="d-flex justify-content-end">
                                    <a
                                      href={`mailto:${
                                        pData?.recipient_email
                                          ? pData?.recipient_email.text
                                          : "test@example.com"
                                      }?subject=${title.text}`}
                                    >
                                      <Button variant="dark">Apply</Button>
                                    </a>
                                  </Box>
                                </Box>
                              )
                            )}
                        </Box>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col lg="3">
                  <ProductAccordion />
                  <ProductSlider />
                </Col>
              </Row>
            </Container>
          </Section>

          <div id="support">
            <Support />
          </div>
          <div id="contact">
            <Contact />
          </div>
        </PageWrapper>
      </div>
    </>
  )
}
export default Career

export const query = graphql`
  query($lang: String!) {
    prismicCareerPage(lang: { eq: $lang }) {
      data {
        title {
          text
        }
        cover_image {
          url
        }
        section_title {
          text
        }
        section_content {
          html
        }
        job_listing_title {
          text
        }
        recipient_email {
          text
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
      }
    }

    allPrismicJob(
      sort: { order: ASC, fields: data___order }
      filter: { lang: { eq: $lang }, data: { is_open: { eq: true } } }
    ) {
      nodes {
        data {
          date(formatString: "DD-MM-YY")
          title {
            text
          }
          content {
            html
          }
        }
      }
    }

    prismicCommon(lang: { eq: $lang }) {
      data {
        btn_home {
          text
        }
      }
    }
  }
`
